<template>
    <div>
        <div class="header">
            <keep-alive>
                <manager-topbar-comp></manager-topbar-comp>
            </keep-alive>
        </div>
        <div class="container">
            <div class="aside aside01">
                <today-statistic-comp></today-statistic-comp>
            </div>
            <router-view></router-view>
            <div class="aside aside02">
                <online-user-comp></online-user-comp>
            </div>
        </div>
    </div>
</template>

<script>
    import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";
    import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
    import OnlineUserComp from "../../components/administrator/OnlineUserComp";
    export default {
        name: "Administrator",
        components: {OnlineUserComp, TodayStatisticComp, ManagerTopbarComp},
        created(){
        },
    }
</script>

<style scoped>
    @import url("../../assets/css/adminsitrator.css");
</style>